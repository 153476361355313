<template>
  <div class="pt-3 chat__list">
    <h2 class="mb-5 px-3">Messages</h2>

    <v-list two-line color="rgba(0,0,0,0)" class="pa-0 px-3">
      <template>
        <v-list-item
          @click="selectChat(chatInfo.client_id)"
          class="active pa-0"
          style="position: relative"
        >
          <template>
            <v-list-item-content
              class="flex-row d-flex"
              style="width: 55%; flex: unset"
            >
              <div class="d-flex">
                <v-list-item-title style="font-size: 14px"
                  >Admin</v-list-item-title
                >
              </div>

              <div
                style="
                  position: absolute;
                  right: 5px;
                  font-size: 11px;
                  transform: translateY(-50%);
                  top: 40%;
                "
                v-show="chatInfo.updated_at"
              >
                {{ formattedDate(String(chatInfo?.updated_at)) }}
              </div>
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('chats')
const { mapGetters } = createNamespacedHelpers('auth')

export default {
  name: 'ChatList',
  computed: {
    ...mapState(['chats', 'chatInfo']),
    ...mapGetters(['currentUser']),
    currentChat() {
      return this.$route.params.id
    },
  },
  methods: {
    ...mapActions(['fetchChatInfoWholesale', 'fetchChatInfo']),
    selectChat(id) {
      this.$router.push({ name: 'chat dialog', params: { id } }).catch(() => {})
    },
    formattedDate(dateString) {
      const dateParts = dateString.split(' ')[0].split('-')
      const year = dateParts[0]
      const month = dateParts[1]
      const day = parseInt(dateParts[2])

      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
      ]

      return `${day} ${months[month - 1]}`
    },
  },
  mounted() {
    if (this.currentUser.role === 'admin') {
      this.fetchChatInfoWholesale()
    } else {
      this.fetchChatInfo()
    }
  },
}
</script>

<style scoped>
.active {
  background-color: #f2f2f2 !important;
}
</style>
<style lang="scss">
.chat__list {
  .v-input {
    &__slot {
      background-color: transparent !important;
      padding: 0 !important;
    }
    &__prepend-outer {
      margin: 0 10px 0 0 !important;
    }
  }
}
</style>
